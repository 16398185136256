import React from "react";
import "./global.scss";

function Layout({ component: Component = "main", children, ...rest }) {
  return (
    <>
      <Component {...rest}>{children}</Component>
    </>
  );
}

export default Layout;
