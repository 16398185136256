import * as React from "react";
import Layout from "../components/layout";
import * as styles from "./index.module.scss";
import GithubIcon from "../../assets/github-mark.inline.svg";
import LinkedInIcon from "../../assets/linkedin.inline.svg";

// markup
const IndexPage = () => {
  return (
    <Layout component="main">
      <section className={styles.full_bleed}>
        <h1>Hi, I'm Zach Smith.</h1>
        <p>
          I'm a developer and UI architect with over a decade of experience
          building quality web experiences. I enjoy creating simple and elegant
          solutions using modern technology.
        </p>
      </section>

      <section className={`${styles.full_bleed} ${styles.skills}`}>
        <h2>My skills</h2>
        <ul>
          <li>Responsive Web Development</li>
          <li>UI Architecture</li>
          <li>Design Systems</li>
          <li>Accessibility Audits</li>
        </ul>
      </section>

      <section className={styles.full_bleed}>
        <h2>My philosophy</h2>
        <div>
          <h3>Empathy and humility</h3>
          <blockquote>It's about the user.</blockquote>
          <p>
            I believe we should strive to meet our users where they are and
            minimize assumptions we make about them. The world is full of unique
            people and you don't always know who will be accessing your site,
            let alone how or when they do. I build sites with semantic HTML and
            follow accessibility best practices as often as I can, to make the
            web an inclusive space. I also have experience internationalizing
            and localizing large web sites so users can feel more at home.
          </p>
        </div>
        <div>
          <h3>Always learning</h3>
          <blockquote>Best practices are a moving target.</blockquote>
          <p>
            Technology moves quickly on the web and I love learning new things.
            The chance to use a new technique or learn a new approach excites
            me, and I always try to do things the best way that I can. I'm not
            afraid to say I don't know something and I'll dive into learning it
            when I can.
          </p>
        </div>
        <div>
          <h3>Flexible and adaptable</h3>
          <p>
            Things change. Requirements, projects, scope, design, even
            implementation &mdash; and I like changing with them. I'm agile and
            more than willing to adapt to the needs of the project and team.
          </p>
        </div>
        <div>
          <h3>Deliver value</h3>
          <p>
            I believe in first making a solution work, then making it right,
            then making it fast. This lets me quickly learn if an approach is
            viable before investing too much time into optimizing it.
          </p>
          <p>
            I prioritize substance over flash as much as I can. I view it as an
            obligation to my users to be responsible with their privacy and
            their resources, such as their bandwidth and CPU. Not every user has
            a powerful device or a strong connection. I strive for the balance
            between visual aesthetics and lively animations and delivering a
            minimally obstrusive experience. I love it when there is a solution
            that adheres to the design's intentions but in an efficient way.
          </p>
        </div>
      </section>

      <section className={styles.full_bleed}>
        <h2>My experience</h2>
        <p>
          I've had the privilege to work on a variety of projects in my career
          and in a number of capacities. In my current role as a UI Architect I
          assist developers, designers, product analysts, and QA analysts as
          they design and build modern, accessible, and scalable interfaces.
        </p>
        <ul>
          <li>I've contributed to internal design systems</li>
          <li>
            I've developed features for and supported large, public facing sites
          </li>
          <li>I've worked on mobile apps using Web technologies</li>
          <li>I've been a tech lead in charge of a team of developers</li>
          <li>
            My current role consists of assisting dev teams as they implement
            modern UI techniques and tools, and researching novel UI stacks for
            new projects
          </li>
        </ul>
      </section>

      <footer className={styles.full_bleed}>
        <a
          href="https://www.linkedin.com/in/zach-smith-5508a5134/"
          className={styles.footerIconLink}
          aria-label="LinkedIn profile"
        >
          <LinkedInIcon />
        </a>
        <a
          href="https://github.com/zachsmithary"
          className={styles.footerIconLink}
          aria-label="Github profile"
        >
          <GithubIcon />
        </a>
      </footer>
    </Layout>
  );
};

export default IndexPage;
